import './import/modules.js';

$(window).on('load', function () {
  const $mainSlider = document.querySelector('.js-slider-main');

  if ($mainSlider) {
    const mainSlider = new Swiper($mainSlider, {
      loop: true,
      effect: 'fade',
      slidesPerView: 1,
      pagination: {
        el: $mainSlider.querySelector('.swiper-pagination'),
        clickable: true,
      },
      breakpoints: {
        680: {
          navigation: {
            nextEl: $mainSlider.querySelector('.swiper-button-next'),
            prevEl: $mainSlider.querySelector('.swiper-button-prev'),
          },
        },
      }
    });
  }


  const $tabSection = document.querySelectorAll('[data-tab]');
  const $btnTab = document.querySelectorAll('[data-btn]');

  const sectionTab = (elem) => {
    const dataBtn = elem.dataset.btn;
    elem.closest('.js-tab-section').querySelectorAll('[data-tab]').forEach(elem => {
      elem.classList.remove('active')
    });
    elem.closest('.js-tab-section').querySelectorAll('[data-btn]').forEach(elem => {
      elem.classList.remove('active')
    });
    elem.classList.add('active');
    $tabSection.forEach(element => {
      const dataSection = element.dataset.tab;
      if (dataBtn === dataSection) {
        element.classList.add('active');
      }
    });
  }

  const changeRequired = (item) => {
    document.querySelectorAll('.js-input-req').forEach(item => {
      item.removeAttribute('data-req');
    });
    document.querySelector(`[data-tab="${item.dataset.btn}"]`).querySelectorAll('.js-input-req').forEach(item => {
      item.setAttribute('data-req', '')
    });
  }



  $btnTab.forEach(item => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      sectionTab(e.currentTarget)
      if (e.currentTarget.classList.contains('js-tab-button')) {
        changeRequired(e.currentTarget);
      }
    })
  });

  const $infoSlider = document.querySelectorAll('.js-slider-info');

  if ($infoSlider) {
    $infoSlider.forEach(item => {
      new Swiper(item, {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
          el: item.querySelector('.swiper-pagination'),
          clickable: true,
        },
        navigation: {
          nextEl: item.querySelector('.swiper-button-next'),
          prevEl: item.querySelector('.swiper-button-prev'),
        },

        breakpoints: {
          575: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }
      });
    });
  }

  const $educationSlider = document.querySelectorAll('.js-slider-education');

  if ($educationSlider) {
    $educationSlider.forEach(item => {
      const educationSliderSwiper = new Swiper(item, {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
          el: item.querySelector('.swiper-pagination'),
          clickable: true,
        },
        navigation: {
          nextEl: item.querySelector('.swiper-button-next'),
          prevEl: item.querySelector('.swiper-button-prev'),
        },
        breakpoints: {
          1200: {
            slidesPerView: 2,
            spaceBetween: 20
          }
        }
      });
    });
  }

  const $phoneMask = $('.js-phone-mask');
  $phoneMask.mask("8(999)9999999");

  const $productSlider = document.querySelectorAll('.js-slider-product');

  if ($productSlider) {
    $productSlider.forEach(item => {
      const productSliderSwiper = new Swiper(item, {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
          el: item.querySelector('.swiper-pagination'),
          clickable: true,
        },
        navigation: {
          nextEl: item.querySelector('.swiper-button-next'),
          prevEl: item.querySelector('.swiper-button-prev'),
        },
        autoHeight: true,
        breakpoints: {
          575: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }
      });
    });
  }



  document.querySelectorAll('.js-btn-order').forEach(item => {
    item.addEventListener('click', (e) => e.currentTarget.classList.toggle('active'));
  });

  const $counter = document.querySelectorAll('.js-counter-btn');

  const countOrder = (elem) => {
    const dataAction = elem.dataset.action;
    const $elemCount = elem.closest('.js-counter-block').querySelector('.js-counter');
    const currentCount = parseInt($elemCount.innerText)
    if (currentCount === 1 && dataAction === 'minus') {
      elem.setAttribute("disabled", "disabled");
    }
    if (dataAction === 'minus') {
      $elemCount.innerText = currentCount - 1
    } else {
      $elemCount.innerText = currentCount + 1
      elem.closest('.js-counter-block').querySelector('[data-action="minus"]').removeAttribute('disabled')
    }
  }

  $counter.forEach(item => {
    item.addEventListener('click', (e) => countOrder(e.currentTarget))
  })

  const $btnComparison = document.querySelectorAll('.js-add-comparison');

  $btnComparison.forEach(item => {
    item.addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('active')
    })
  });;

  const $btnSubmenu = document.querySelectorAll('.js-open-submenu');

  $btnSubmenu.forEach(item => {
    item.addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('active')
    })
  });;

  const $btnPagination = document.querySelectorAll('.js-pagination-button');

  if ($btnPagination) {
    $btnPagination.forEach(item => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        $btnPagination.forEach(item => {
          item.classList.remove('active');
        })
        e.currentTarget.classList.add('active');
      })
    })
  };

  const openModal = (modal) => {
    document.querySelector(`[data-modal-section="${modal}"]`).classList.add('active');
  }

  const $btnOpenModal = document.querySelectorAll('.js-open-modal');

  if ($btnOpenModal) {
    $btnOpenModal.forEach(item => {
      if (item.classList.contains('navigation__link--open')) {
        item.addEventListener('click', (e) => {
          if (e.currentTarget.classList.contains('open')) {
            document.querySelector(`[data-modal-section="menu"]`).classList.remove('active');
            e.currentTarget.classList.remove('open')
          } else {
            document.querySelector(`[data-modal-section="menu"]`).classList.add('active');
            e.currentTarget.classList.add('open')
          }
        })
      } else {
        item.addEventListener('click', (e) => {
          openModal(e.currentTarget.dataset.modal);
        })
      }
    });
  }

  const $btnCloseModal = document.querySelectorAll('.js-close-modal');

  if ($btnCloseModal) {
    $btnCloseModal.forEach(item => {
      item.addEventListener('click', (e) => {
        e.currentTarget.closest('.modal').classList.remove('active');
      })
    })
  }

  const $customSelect = $('.js-select');

  if ($customSelect) {
    $customSelect.select2({
      placeholder: '',
      minimumResultsForSearch: -1
    })
  };

  const $btnOrderButton = document.querySelectorAll('.js-order-open');

  if ($btnOrderButton) {
    $btnOrderButton.forEach(item => {
      item.addEventListener('click', (e) => {
        e.currentTarget.closest('.js-order-container').classList.toggle('active')
      })
    })
  };

  const $btnEdit = document.querySelectorAll('.js-button-edit');

  if ($btnEdit) {
    $btnEdit.forEach(item => {
      const $btnSave = item.closest('.js-setting-section').querySelector('.js-button-save');
      const $inputSetting = item.closest('.js-setting-section').querySelectorAll('.form__input--setting');

      item.addEventListener('click', (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove('active');
        $btnSave.classList.add('active');

        $inputSetting.forEach(item => {
          item.removeAttribute('disabled')
        });
      });

      $btnSave.addEventListener('click', (e) => {
        e.currentTarget.classList.remove('active');
        item.classList.add('active');

        $inputSetting.forEach(item => {
          item.setAttribute('disabled')
        });
      });
    })
  };

  $('.modal').mouseup(function (e) {
    if ($(".modal__inner").has(e.target).length === 0) {
      $(this).removeClass('active');
      $('.js-open-modal').removeClass('open');
    }
  });

  const filterRange = document.querySelector('.js-filter-range');

  if (filterRange) {
    const start = 100;
    const end = 800
    noUiSlider.create(filterRange, {
      start: [start, end],
      step: 10,
      margin: 20,
      connect: true,
      behaviour: 'tap-drag',
      range: {
        'min': 0,
        'max': 1000
      },
      format: wNumb({
        decimails: 0
      })
    });

    var minCostInput = document.getElementById('minCost'),
      maxCostInput = document.getElementById('maxCost');

    // When the slider value changes, update the input and span
    filterRange.noUiSlider.on('update', function (values, handle) {
      if (handle) {
        maxCostInput.value = values[handle];
      } else {
        minCostInput.value = values[handle];
      }
    });

    filterRange.addEventListener('change', function () {
      filterRange.noUiSlider.set([null, this.value]);
    });
  };

  const $filterReset = document.querySelectorAll('.js-filter-reset');

  if ($filterReset) {
    $filterReset.forEach(item => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        e.currentTarget.closest('.js-filter-form').querySelectorAll('.custom__checkbox-hidden').forEach(item => {
          item.checked = false
        });
        filterRange.noUiSlider.reset()
      });
    });
  };

  const $productList = $('.js-list-product');

  if ($productList) {
    $('.js-view-list-product').on('click', function () {
      $(this).addClass('active');
      $('.js-view-tile-product').removeClass('active');
      $productList.addClass('row');
    });

    $('.js-view-tile-product').on('click', function () {
      $(this).addClass('active');
      $('.js-view-list-product').removeClass('active');
      $productList.removeClass('row');
    });
  }

  const $cardSlider = document.querySelectorAll('.js-card-slider');
  const $cardSliderThumbs = document.querySelectorAll('.js-card-slider-thumbs')

  if ($cardSlider) {



    $cardSlider.forEach(item => {
      const cardSliderSwiperThumbs = new Swiper(item.closest('.card__slider').querySelector('.js-card-slider-thumbs'), {
        slidesPerView: 4,
        spaceBetween: 5,

        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        direction: 'vertical',
        breakpoints: {
          680: {
            direction: 'horizontal',
            spaceBetween: 14,
            slidesPerView: 7,
          },
        }
      });


      const cardSliderSwiper = new Swiper(item, {
        slidesPerView: 1,
        navigation: {
          nextEl: item.closest('.card__slider-main-wrap').querySelector('.swiper-button-next'),
          prevEl: item.closest('.card__slider-main-wrap').querySelector('.swiper-button-prev'),
        },
        thumbs: {
          swiper: cardSliderSwiperThumbs
        }
      });
    })
  }

  const textDesc = $('.js-text-card');

  if (textDesc) {
    if (textDesc.height() > 96) {
      $('.button__card').css('display', 'flex')
    }

    $('.button__card').on('click', function () {
      $(this).toggleClass('open')
      $(this).closest('.card__info-part').find('.card__info-text').toggleClass('open')
    })
  }

  const imageZoom = $('.card__slider-main--zoom .card__slider-item img')

  if (imageZoom) {
    imageZoom.wrap('<div class="card__slider-image"></div>')
      .css('display', 'block')
      .parent()
      .zoom({
        url: $(this).data('src')
      });
  };

  const $btnRemove = $('.js-basket-remove');

  if ($btnRemove) {
    $btnRemove.on('click', function () {
      $(this.closest('.js-basket-item').remove());
    });
  };

  const $radioTab = $('[data-radio-tab]');

  if ($radioTab) {
    $radioTab.on('change', function () {
      $radioTab.closest('.order__delevery-item').removeClass('active');
      $(this).closest('.order__delevery-item').addClass('active');
      $(this).closest('.order__delevery-inner').find('[data-tab]').prop('checked', false);
      $(this).closest('.order__delevery-inner').find('[data-tab]').prop('disabled', true);
      $(this).closest('.order__delevery-item').find('[data-tab]').prop('disabled', false);
      $(this).closest('.order__delevery-item').find('.order__delevery-choise-item:first-child [data-tab]').prop('checked', true);
      $(this).closest('.order__delevery-item').find('.order__delevery-choise-item:first-child [data-tab]').trigger('change');
    });
  }

  const $radioSection = $('.js-tab-delevery');

  $radioSection.on('change', function () {
    $('[data-section]').removeClass('active');
    const dataTab = $(this).data('tab');
    $(`[data-section=${dataTab}]`).addClass('active')
  });

  $('.js-open-search').on('click', function () {
    $(this).closest('.search__wrap').find('.search__form').toggleClass('active');
  });

  $('.js-open-catalog').on('click', function () {
    $(this).toggleClass('open');
    $('.menu__list').toggleClass('open');
  });

  $('.js-open-filter').on('click', function () {
    $('.filter__mobile').addClass('active');
  });

  $('.js-filter-close').on('click', function () {
    $('.filter__mobile').removeClass('active');
  });


  //Validation

  const form = document.querySelectorAll('.js-form');


  if (form) {

    const validationCheck = (e) => {
      e.preventDefault();


      // Проверка на обязательность заполнения
      function reqCheck(elem) {
        if (elem.hasAttribute("data-req")) {
          validErrors.push(elem);
        };
      };

      // Убрать указание об ошибке
      function noErrors(elem) {
        let elemParent = elem.parentElement;
        // while(!elemParent.classList.contains("form__item")){
        //     elemParent = elemParent.parentElement;
        // };
        if (elemParent.classList.contains("js-valid-error")) {
          elemParent.classList.remove("js-valid-error");
        };
      };

      // Проверка прилагаемого файла
      function fileCheck(elem, file) {
        if (file.size > 5000000) {
          validErrors.push(elem);
        } else {
          noErrors(elem);
        };
      };

      // Проверка вводимых данных через регулярное выражение
      function valueCheck(elem, val, patrn) {
        console.log(elem, val, patrn)
        if (!patrn.test(val)) {
          validErrors.push(elem);
        } else {
          noErrors(elem);
        };
      };

      // Ищем форму, к которой относится кнопка
      let formInner = e.currentTarget;

      while (formInner.tagName != "FORM") {
        formInner = formInner.parentElement
      };

      // Ищем все элементы данной формы
      let formElems = formInner.querySelectorAll("input, select, textarea");

      // Создаем массив для полей с ошибками
      let validErrors = [];

      // Основной цикл проверки на правильность заполнения формы
      for (let i = 0; i < formElems.length; i++) {

        let elemType;
        if (formElems[i].hasAttribute("type")) {
          elemType = formElems[i].getAttribute("type");
        } else {
          elemType = formElems[i].getAttribute("data-type");
        }

        switch (elemType) {

          // Для инпутов
          case "text":
            if (formElems[i].value == "") {
              reqCheck(formElems[i]);
            } else {
              switch (formElems[i].getAttribute("data-type")) {
                case "surname":
                case "name":
                  let namePattern = new RegExp("^[a-zа-яё -]{1,}$", "i");
                  valueCheck(formElems[i], formElems[i].value, namePattern);
                  break;
                case "phone":
                  let phonePattern = new RegExp(/8\(\d{3}\)\d{7}/g);
                  valueCheck(formElems[i], formElems[i].value, phonePattern);
                  break;
                case "date":
                  let datePattern = new RegExp("^[0-9]{1,4}[.]{1}[0-9]{1,4}[.]{1}[0-9]{1,4}$");
                  valueCheck(formElems[i], formElems[i].value, datePattern);
                  break;
                case "mail":
                  let mailPattern = new RegExp("^[a-z0-9_-]{1,}@{1}[a-z]{1,}[.]{1}[a-z]{2}$", "i");
                  valueCheck(formElems[i], formElems[i].value, mailPattern);
                  break;
                case "inn":
                  let innPattern = new RegExp("^[0-9]{12,}$");
                  valueCheck(formElems[i], formElems[i].value, innPattern);
                  break;

              };
            };
            break;

          case "password":
            if (formElems[i].value == "") {
              reqCheck(formElems[i]);
            } else {
              noErrors(formElems[i]);
            };
            break;
            // Для текстовых полей
          case "textarea":
            if (formElems[i].value == "") {
              reqCheck(formElems[i]);
            } else {
              noErrors(formElems[i]);
            };
            break;

            // Для селектов
          case "select":
            if (formElems[i].value == "") {
              reqCheck(formElems[i]);
            } else {
              noErrors(formElems[i]);
            };
            break;

            // Для чекбоксов
          case "checkbox":
            if (!formElems[i].checked) {
              reqCheck(formElems[i]);
            } else {
              noErrors(formElems[i]);
            };
            break;

            // Для файлов
          case "file":
            if (!formElems[i].files[0]) {
              reqCheck(formElems[i]);
            } else if (formElems[i].files[0]) {
              fileCheck(formElems[i], formElems[i].files[0]);
            };
            break;
        };
      };

      // Проверка, есть ли поля с ошибками заполнения, отмена отправки, и назначение подсказок об ошибках
      if (validErrors.length) {
        event.preventDefault();
        for (let i = 0; i < validErrors.length; i++) {

          let elemParent = validErrors[i].parentElement;

          while (!elemParent.classList.contains("js-form-item")) {
            elemParent = elemParent.parentElement;
          };
          if (!elemParent.classList.contains("js-valid-error")) {
            elemParent.classList.add("js-valid-error")
          };
        };
      };
    };

    form.forEach((item) => {
      item.addEventListener('submit', validationCheck)
    });
  }
});